import { HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class OdataParser {
  private entity = ''
  private filterColumns!: string[]
  private filters: string[] = []
  private orFilters: string[] = []

  private queryParams = '?'

  private queryParamsObject = {}

  appendQueryParams(params: string) {
    this.queryParamsObject = {
      ...this.queryParamsObject,
      ...{ [this.entity + params.split('=')[0]]: params.split('=')[1] }
    }

    return this.queryParams === '?'
      ? (this.queryParams += `${this.entity}${params}`)
      : (this.queryParams += `&${this.entity}${params}`)
  }

  setContainFilterColumns(columns: string[]) {
    this.filterColumns = columns
    return this
  }

  setInPropertyFilterColumns(
    column: string,
    values: string[] | number[] | (string | number)[],
    prefix = '',
    suffix = ''
  ) {
    const fixedValues = values.map(value =>
      typeof value === 'string' ? `'${value}'` : value
    )
    this.filters.push(`${prefix}${column} in (${fixedValues})${suffix}`)
    return this
  }

  setContainFilter(searchCriteria: string, useOrFilterList = false) {
    const filter = this.filterColumns
      .map(
        column => `contains(${column}, '${searchCriteria.replace(/'/g, "''")}')`
      )
      .join(' or ')
    if (!useOrFilterList) this.filters.push(`(${filter})`)
    else this.orFilters.push(`(${filter})`)
    return this
  }

  setStartsWithFilter(
    columnToSeach: string,
    searchCriteria: string,
    useOrFilterList = false
  ) {
    const filter = `startswith(${columnToSeach}, '${searchCriteria.replace(/'/g, "''")}')`
    if (!useOrFilterList) this.filters.push(`(${filter})`)
    else this.orFilters.push(`(${filter})`)
    return this
  }

  setAnyFilter(column: string, isNegated: boolean) {
    this.filters.push(`${isNegated ? ' not ' : ''}${column}/any()`)
    return this
  }

  setEqualityFilter(column: string, value: unknown) {
    const fixedValue = typeof value === 'string' ? `'${value}'` : value
    const filter = `(${column} eq ${fixedValue})`
    this.filters.push(filter)
    return this
  }

  setAnyAndEqualityFilter(
    column: string,
    values: string[] | number[] | (string | number)[]
  ) {
    const fixedValues = values.map(value =>
      typeof value === 'string' ? `'${value}'` : value
    )

    this.filters.push(
      `${column}/any (${column}: ${column} in (${fixedValues}))`
    )
  }

  setInequalityFilter(column: string, value: unknown) {
    const fixedValue = typeof value === 'string' ? `'${value}'` : value
    const filter = `(${column} ne ${fixedValue})`
    this.filters.push(filter)
    return this
  }

  setCount(count: boolean) {
    this.appendQueryParams(`$count=${count}`)
    return this
  }

  setTop(top: number) {
    this.appendQueryParams(`$top=${top}`)
    return this
  }

  setSkip(skip: number) {
    this.appendQueryParams(`$skip=${skip}`)
    return this
  }

  setOrderBy(orderBy: { column: string | number; direction: 'asc' | 'desc' }) {
    this.appendQueryParams(`$orderby=${orderBy.column} ${orderBy.direction}`)
    return this
  }

  setMultipleOrderBy(
    orderByList: { column: string; direction: 'asc' | 'desc' }[]
  ) {
    const orderBy = orderByList
      .map(order => `${order.column} ${order.direction}`)
      .join(',')
    this.appendQueryParams(`$orderby=${orderBy}`)
    return this
  }

  setSelect(columns: string[]) {
    this.appendQueryParams(`$select=${columns.join(',')}`)
    return this
  }

  prepare(entity: string) {
    this.entity = `odata${entity}=`

    this.queryParams = '?'
    this.queryParamsObject = {}
    this.filters = []
    this.orFilters = []
    return this
  }

  prepareWithoutEntity() {
    this.entity = ''
    this.queryParams = '?'
    this.queryParamsObject = {}
    this.filters = []
    this.orFilters = []
    return this
  }

  getQueryParamsAsString() {
    return this.queryParams
  }
  resolveFilters() {
    if (this.filters.length === 0) return
    const filter = this.filters.map(value => `${value}`).join(' and ')
    const orFilters = this.orFilters.map(value => `${value}`).join(' or ')

    this.appendQueryParams(
      `$filter=${filter}${
        this.orFilters.length > 0 ? ` and (${orFilters})` : ''
      }`
    )
  }

  getQueryParamsAsHttpParams() {
    this.resolveFilters()

    return new HttpParams({
      // fromString: encodeURIComponent(this.queryParams)
      fromObject: this.queryParamsObject
    })
  }
}
